.btn-secondary.active{
  background-color: #efefef;
}

.myNavbar{
  background: #fff!important;
}

body{
  background: #ededed!important;
}

.sidePanel button.btn-link{
  color: #444;
}

.sidePanel button.btn-link:hover{
  color: #444;
  text-decoration: none;
}

.btn{
  border-radius: 0!important;
}

.form-control{
  border-radius: 0!important;
}

.sidePanel button.btn-link:focus{
  color: #444;
  text-decoration: none;
}

.btn-custom-blue{
  background-color: #65A8DD!important;
  border: none;
  color: #fff!important;
}

.btn-custom-blue:disabled{
  background-color: #75B8ED!important;
  border: none;
}

.card{
  border-radius: 0!important;
}

.card-header:first-child{
  border-radius: 0!important;
  background: #65A8DD!important;
  color: #fff!important;
}

@media print {
  .non-printable { display: none; }
  #resultsComponent { 
      display: block; 
  }
  .card{
    border: none!important;
  }
}