.table tr td:nth-child(n+2){
    text-align: center;
}

.table tr th:nth-child(n+2){
    text-align: center;
}

.dataTable thead tr th:nth-last-child(1) span{
    text-transform: uppercase;
}